<template>
	<div v-if="path!='/agreement' && pathName!='Feedback'">
		<router-link :to="'/shop/'+ shopId" class="fixed-home" v-if="pathName!='ShopHome'">
			<p class="iconfont iconshouye1"></p>
			<p class="desc">首页</p>
		</router-link>
		<div class="fixed-bar" v-else>
			<div class="item cutline" @click="createPreOrder">
				<p class="iconfont iconzixun"></p>
				<p class="desc">询价</p>
			</div>
			<div class="item cutline">
				<a :href="telephone ? ('tel:' + telephone) : '#'">
					<p class="iconfont icon54"></p>
					<p class="desc">客服</p>
				</a>
			</div>
			<div class="item" @click="aboutMy">
				<p class="iconfont iconwode-copy-copy"></p>
				<p class="desc">我的</p>
			</div>
		</div>
	</div>
	
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
    export default {
        data: ()=> ({
			telephone:'',
			pathName:'',
			shopId:'',
			path:'',
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
                token: state => state.token,
			}),
			...mapGetters({
				login: 'checkLogin',
				isCustom: 'isCustom',
			})
		},
		created: async function() {
			this.path=this.$route.path
			this.pathName=this.$route.name
			this.shopId=this.$route.params.shopId ? this.$route.params.shopId:''
			if(this.$route.name=='ShopHome' && !!this.shopId){
				let agentInfo=await this.$store.dispatch('getAgentInfo',{shopId:this.shopId})
				this.telephone=agentInfo.telephone
			}
		},
		watch:{
			$route: async function(){
				this.path=this.$route.path
				this.pathName=this.$route.name
				this.shopId=!!this.$route.params ? this.$route.params.shopId:''
				if(this.$route.name=='ShopHome' && !!this.shopId){
					let agentInfo=await this.$store.dispatch('getAgentInfo',{shopId:this.shopId})
					this.telephone=agentInfo.telephone
				}
			}
		},
		mounted: function() {
			
        },
        methods: {
            createPreOrder: function() {
				if(this.$route.name=='CreatePreOrderPage'){
					return
				}
				if(this.isCustom) {
					let params = {}
					params.shopId=  this.shopId
					this.$router.push({ name: 'CreatePreOrderPage', params: params})
				} else {
					Dialog.confirm({
						title: '提示',
						message: '为了方便卖家能与您及时沟通，请验证手机号!',
					})
					.then(() => {
						this.$router.push('/bind-mobile')
					})
					.catch(() => {
						// on cancel
					});
				}
			},
			aboutMy: function() {
				this.$router.push('/my')
			},
        }
    }
</script>

<style scoped>
.fixed-bar {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.7);
	width: 0.8rem;
	/* height: 3.65rem; */
	border-radius: 0.5rem;
	right: 0.4rem;
	bottom: 0.5rem;
	padding-left: 0.1rem;
	padding-right: 0.1rem;
	padding-bottom: 0.1rem;
}
.fixed-bar .item {
	width: 0.8rem;
	height: 1.25rem;
	color: #fff;
	display: block;
}
.fixed-bar .item a{
	color: #fff;
}
.fixed-bar .cutline {
	border-bottom: 0.01rem solid #676767;
}
.fixed-bar .item .iconfont {
	padding-top: 0.3rem;
	height: 0.5rem;
	font-size: 0.42rem;
	text-align: center;
}
.fixed-bar .item .iconfont.icon54{
	font-size: 0.6rem;
    margin-top: -0.2rem;
}
.fixed-bar .item .desc {
	font-size: 0.2rem;
	margin-top: 0.1rem;
	text-align: center;
}
.fixed-home {
	width: 0.94rem;
	height: 0.94rem;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 0.49rem;
	position: fixed;
	right: 0.4rem;
	bottom: 1.72rem;
	color: #fff;
	font-size: 0.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.fixed-home .iconfont {
	height: 0.39rem;
	margin-bottom: 0.05rem;
	font-size: 0.42rem;
}
</style>