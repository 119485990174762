/* eslint-disable */
import REQ_HANDLE from './request.js'
const utils = {

	verifyMobile: function({area, phone}) {
		let reg = null;
		if (area == '86') {
			reg = new RegExp("^((13[0-9])|(14[5-9])|(16[1-2,4-7])|(17[0-8])|(15[0-9])|(18[0-9])|(19[1,8-9]))\\d{8}$");
		} 
		if (area == '852') {
			reg = new RegExp("^([2|5|6|8|9])\\d{7}$")
		}
		if (area == '853') {
			reg = new RegExp("^[6]\\d{7}$")
		}
		if (area == '886') {
			reg = new RegExp("^([0][9])\\d{8}$")
		}
		if (reg) {
			return reg.test(phone)
		}
		return false
    },
    
    getUrlKey: function (name) {
        return (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(decodeURIComponent(location.href)) || [, ""])[1].replace(/\+/g, '%20') || null;
    },

	obj2UrlQuery: function(obj) {
        let url = ''
        for(let key in obj) {
            let v = obj[key]
            if (!v || v == 'null' || v == 'undefined') {
                continue
            }
            url += '&' + key + '=' + v
        }
        if (url) {
            url = url.replace('&', '?')
        }
        return url
	},
	
	uuid: function() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
    
        var uuid = s.join("");
        return uuid;
    },
    getAgentContact:async function(agentId) {
        let url = '/api-mall/agent/contact/' + agentId
        let res = await REQ_HANDLE.agent.$get(url)
        if(!!res) {
            // window.localStorage.setItem('agentContact', res.data)
            return res.data
        }
    }
}
export default utils