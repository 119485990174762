/* eslint-disable */
import Vue from 'vue'
import Meta from 'vue-meta';
import App from './App.vue'
import { Button, Field, Image as VanImage, Dialog, ActionSheet, Icon, Picker, Popup, Form , Toast, Tag, Cell, CellGroup, Tab, Tabs, DatetimePicker, List ,PullRefresh,Loading  } from 'vant';
import router from './router/index';
import store from './store/index';
import '@/assets/global.css';
import '@/assets/iconfont.css';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
process.env.NODE_ENV == 'production' && Sentry.init({
  dsn: 'http://1e00628b5ce04384ac37f79cff530240@sentry.yctop.com/3',
  integrations: [
    new Integrations.Vue({Vue, attachProps: true}),
  ],
  release: process.env.VUE_APP_RELEASE_VERSION,  //版本号
  logErrors: true, //开发环境下打印错误
  // _experiments: { useEnvelope: true },
});

Vue.use(Button);
Vue.use(Field);
Vue.use(VanImage);
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Tag);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DatetimePicker);
Vue.use(Meta);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Loading);




if (process.env.NODE_ENV == 'development') {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}
Vue.config.productionTip = false

//全局错误捕获  与 sentry冲突
// const errorHandler = (error, vm)=>{
//   console.error('抛出全局异常');
//   console.error(vm);
//   console.error(error);
// }
 
// Vue.config.errorHandler = errorHandler;
// Vue.prototype.$throw = (error)=> errorHandler(error,this);

new Vue({
  router,
  store,
  render: h => h(App),
  metaInfo(){
    return {
        title: this.$store.state.metaInfo.title,
        meta: [
            {
                name: "keywords",
                content: this.$store.state.metaInfo.keywords
            }, {
                name: "description",
                content: this.$store.state.metaInfo.description
            }
        ]
    }
},
}).$mount('#app')
