/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios';
import store from '../store/index';
import qs from 'qs';
import UTILS from '../utils/common-utils';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			component: ()=> import('@/pages/Index.vue'),
		},
		{
			path: '/shop/:shopId',
			name:'ShopHome',
			component: ()=> import('@/pages/Shop.vue'),
			meta: {
				metaInfo : {
					title: '指尖货运小店',
				},
				keepAlive: false,
				requiresAuth: false,
			}
		},
		{
			path: '/bind-mobile',
			component: ()=> import('@/pages/BindMobile.vue'),
			meta: {
				metaInfo : {
					title: '绑定手机',
				}
			}
		},
		{
			path: '/product/:shopId/:productId',
			name:'ProductDetail',
			component: ()=> import('@/pages/ProductDetail.vue'),
		},
		{
			path: '/pre-order/create',
			name: 'CreatePreOrderPage',
			component: ()=> import('@/pages/PreOrder/Create.vue'),
			meta: {
				metaInfo : {
					title: '创建询单',
				}
			}
		},
		{
			path: '/historyList',
			component: ()=> import('@/pages/PreOrder/HistoryList.vue'),
			meta: {
				metaInfo : {
					title: '历史询单',
				}
			}
		},
		{
			path: '/quotation/:shopId/:preOrderId',
			name: 'preOrderDetail',
			component: ()=> import('@/pages/PreOrder/Detail.vue'),
			meta: {
				metaInfo : {
					title: '询单详情',
				}
			}
		},
		{
			path: '/my',
			component: ()=> import('@/pages/My/My.vue'),
			meta: {
				metaInfo : {
					title: '我的',
				}
			}
		},
		{
			path: '/favor-list',
			component: ()=> import('@/pages/My/FavorList.vue'),
			meta: {
				metaInfo : {
					title: '关注',
				}
			}
		},
		{
			path: '/feedback/:shopId/:preOrderId',
			name: 'Feedback',
			component: ()=> import('@/pages/PreOrder/Feedback.vue'),
			meta: {
				metaInfo : {
					title: '历史询价反馈',
				}
			}
		},
		{
			path: '/err',
			component: ()=> import('@/pages/Err'),
		},
		{
			path: '/agreement',
			component: ()=> import('@/pages/My/agreement.vue'),
			meta: {
				metaInfo : {
					title: '用户协议',
				}
			}
		},

	]
})

router.beforeEach((to, from, next) => {
    if (to.meta.metaInfo)
        store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
    next()
});
router.beforeEach(async(to, from, next)=> {
	let path = to.path
	if(path == '/err' || path == '/bind-mobile') {
		next()
		return
	}
	let code = to.query.code || null,
		state = to.query.state || null,
		isLogin = store.getters['checkLogin']
	if (isLogin) {
		try {
			await store.dispatch('loginByPassToken')
		} catch (error) {
			error && console.error(error)
			let { posterKey } = to.query 
			!!posterKey && (path += '?posterKey=' + posterKey)
			let redirectUrl = encodeURIComponent(path)
			next('/bind-mobile?redirectUrl=' + redirectUrl)
			return
		}
		next()
		return
	}
	if (!!code && !!state) {
		let wxAuthSuccess = false
		try {
			let res = await store.dispatch('wxAuth', {code})
			wxAuthSuccess = true
		} catch (err) {
			let error = {
				err,
				from,
				to
			}
			sessionStorage.setItem('login-error', JSON.stringify(error))
		}
		if (wxAuthSuccess) {
			try {
				await store.dispatch('loginByPassToken')
			} catch (error) {
				error && console.error(error)
				let redirectUrl = encodeURIComponent(path)
				next('/bind-mobile?redirectUrl=' + redirectUrl)
				return
			}
			next()
			return
		} else {
			next('/err')
			return
		}
	} else {
		let href = window.location.protocol + '//' + window.location.host + window.location.pathname//window.location.href
		if (href.indexOf('agent-mall.yctop.com') > -1) {
			href = href.replace('agent-mall.yctop.com', 'agent-mall.efreight.cn/dev')
		}
		let { posterKey } = to.query 
		!!posterKey && (href += '?posterKey=' + posterKey)
		let REDIRECT_URI = encodeURIComponent(href) // encodeURIComponent('http://agent-mall.yctop.com')
		window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx60ced1c8de7908d5&redirect_uri=' + REDIRECT_URI
					+ '&response_type=code&scope=snsapi_userinfo&state=AUTH#wechat_redirect', '_self')
	}
})

router.afterEach(async (to, from) => {

	let shopId = to.params.shopId || '',
		productId = to.params.productId || '',
		key = to.query.posterKey || ''
	if(!!shopId) {
		//店铺访问量
		try {
			await axios.get('/api-mall/custom/visit/' + shopId)
		} catch(err){}
	}
	if(!!key) {
		//记录海报分享后打开的次数
		try {
			await axios.get('/api-mall/poster/view/' + key)
		} catch(err){}
	}

	if(process.env.NODE_ENV != 'production') return;

	let obj = {}
	obj.from = from.path
	obj.to = to.path
	obj.shopid = shopId
	obj.productid = productId
	obj.uid = (store.state && store.state.mall_usr && store.state.mall_usr.id) || ''
	let uuid = getCookie('g_efreight_uid') || ''
	if(!uuid) {
		uuid = UTILS.uuid().replace(/-/g, '')
		document.cookie = "g_efreight_uid=" + uuid
	}
	obj.uuid = uuid
	obj.posterid = to.query.posterId || ''
	obj.v = "0.1.0"
	obj.ds = window.screen.width + 'X' + window.screen.height
	try {
		let header = {};
		header['Content-Type']= 'application/x-www-form-urlencoded';
		await axios.post('/hm.gif', qs.stringify(obj), { headers: header})
	} catch (err) {

	}

});

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
         }
         if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
         }
     }
    return "";
}

export default router;