<template>
    <div style="height: 100%;">
        <Content></Content>
    </div>
</template>

<script>
import Content from '@/components/Content';
export default {
    components: {
        Content,
    }
}
</script>

<style scoped>

</style>>