<template>
  <div style="height: 100%;">
    <Layout></Layout>
  </div>
</template>

<script>
  /* eslint-disable */
import Layout from '@/layouts/Index';
export default {
  name: 'App',
  components: {
    Layout,
  },
  mounted: function() {
    console.log('v-2020-10-13')
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
