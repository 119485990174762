'use strict';
/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';

Vue.use(Vuex);
const USER_DO = {
    id: '',
    phone: '',
}
const MALL_USR = {
    id: '',
    phone: '',  
    area: '',
}
const WX_USR = {
    unionid: '',
    openid: '',
    nickname: '',
    sex: '',
    head_img_url: ''
}

const state = {
    metaInfo: {
        title: "指尖货运小店",
        keywords: "",
        description: ""
    },
    usr: window.localStorage.usr ? JSON.parse(window.localStorage.usr) : USER_DO,   //H5用户-通行证数据
    wx_usr: window.localStorage.wx_usr ? JSON.parse(window.localStorage.wx_usr) : WX_USR,  //H5用户-微信数据
    token: window.localStorage.token ? window.localStorage.token : '',
    mall_usr: window.localStorage.mall_usr ? JSON.parse(window.localStorage.mall_usr) : MALL_USR,  //H5用户-小店数据
};


const mutations = {
    updatePageTitle: (state, payload)=> {
        state.metaInfo = Object.assign({}, state.metaInfo, {
            title: payload.title
        })
        document.title = payload.title
        let i = document.createElement('iframe');
        i.style.display = 'none';
        i.onload = function(){
            setTimeout(function(){
                i.remove();
            }, 9);
        };
        document.body.appendChild(i);
        // state.metaInfo = {
        //     title: payload.title
        // }
    },
    CAHNGE_META_INFO:(state, metaInfo)=> {
        state.metaInfo = metaInfo;
    },
    updateToken: (state, payload)=> {
        state.token = payload.token
        window.localStorage.setItem('token', state.token)
        if (!!state.token) {
            let millis = new Date().getTime()
            window.localStorage.setItem('tokenValidMilis', millis + 3600 * 1000 * 24)
        } else {
            window.localStorage.removeItem('tokenValidMilis')
        }
    },
    updateUsrInfo: (state, payload)=> {
        let {pass_usr_info, wx_usr_info} = payload,
            {phone = '', id = ''} = pass_usr_info
        state.usr = Object.assign({}, {phone, id})
        state.wx_usr = Object.assign({}, wx_usr_info)
        window.localStorage.setItem('usr', JSON.stringify(state.usr))
        window.localStorage.setItem('wx_usr', JSON.stringify(state.wx_usr))
    },
    updateMallUsrInfo: (state, payload)=> {
        let {id = '', phone = '', area = ''} = payload
        state.mall_usr = Object.assign({}, {id, phone, area}) 
        window.localStorage.setItem('mall_usr', JSON.stringify(state.mall_usr))
    },
	updateDB: state => {
		window.localStorage.setItem('usr', JSON.stringify(state.usr))
    },
};
const getters = {
    
    checkLogin: (state, getters, rootState) => {
        let millis = new Date().getTime(),
            tokenValidMilis = window.localStorage.tokenValidMilis || 0
        return !!state.token && tokenValidMilis && millis < tokenValidMilis
    },

    hasBoundPhone: (state) => {
        return !!state.usr.phone
    },

    isCustom: (state, getters, rootState) => {
        return !!state.mall_usr && !!state.mall_usr.phone && !!state.mall_usr.id
        //checkCustomValidation true 直接跳转， false则跳转授权
    },
}
const modules = {
}
export default new Vuex.Store({
    state,
    mutations,
    modules,
    actions,
    getters
})