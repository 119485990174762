<template>
    <div  style="height: 100%;">
        <router-view></router-view>
        <div v-if="routePath!='/favor-list' && routePath!='/my' && routePath!='/historyList' && routeName!='ProductDetail' && routeName!='preOrderDetail'">
            <FixedBar></FixedBar>  
        </div>
        
    </div>
</template>

<script>
import FixedBar from '@/components/FixedBar';
export default {
    components: {
        FixedBar,
    },
    data: ()=> ({
        routePath:'',
        routeName:'',
    }),
    created: function() {
        this.routePath=this.$route.path
        this.routeName=this.$route.name
    },
    watch:{
        $route: async function(){
            this.routePath=this.$route.path
            this.routeName=this.$route.name
        }
    },
}
</script>

<style scoped>

</style>