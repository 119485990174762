/* eslint-disable */
import axios from 'axios';
import qs from 'qs';

axios.defaults.withCredentials=true;

const reg = new RegExp('[\u4e00-\u9fa5]')
const errorHandler = function(code, msg) {
	let codeMsg = errorEnums[code] ? errorEnums[code] : ''
	if (msg) {
		if (reg.test(msg)) {
			return msg
		}
		return codeMsg + msg
	} else {
		return codeMsg
	}
}
const YC_REQUEST_HANDLER = {
	post: function({token, usrId, url, data}, successCallBack, failCallBack) {
		var header = {}
		header['Content-Type']= 'application/x-www-form-urlencoded';
		if (token) {
			header['Authorization'] = 'Bearer ' + token
		}
		if (usrId) {
			url = url + "?usrId=" + usrId
		}
		axios.post(url, qs.stringify(data), { headers: header})
			.then(
				function(response) {
					response = response.data
					if (!!response && !!response.code) {
						var code = response.code
						if (code == 200) {
							!!successCallBack && successCallBack(response.data)
						} else {
							sessionStorage.setItem('error', JSON.stringify(response))
							!!failCallBack && failCallBack(errorHandler(response.code, response.message));
						}
					} else {
						sessionStorage.setItem('error', JSON.stringify(response))
						!!failCallBack && failCallBack('请求失败，请联系客服')
					}
				},
				function(error) {
					sessionStorage.setItem('error', JSON.stringify(error))
					!!failCallBack && failCallBack('网络错误');
				}
			)
	},



	get: function({url, data, token, usrId}, successCallBack, failCallBack) {
		data.t = new Date().getTime()
		var header = {}
		if (token) {
			header['Authorization'] = 'Bearer ' + token
		}
		if (usrId) {
			data.usrId = usrId
		}
		axios.get(url, {'params': data, headers: header})
			.then(
				function(response) {
					response = response.data
					if (!!response && !!response.code) {
						var code = response.code
						if (code == 200) {
							!!successCallBack && successCallBack(response.data)
						} else {
							sessionStorage.setItem('error', JSON.stringify(response))
							!!failCallBack && failCallBack(errorHandler(response.code, response.message))
						}
					} else {
						sessionStorage.setItem('error', JSON.stringify(response))
						!!failCallBack && failCallBack('未知错误，请联系客服！')
					}
				},
				function(error) {
					sessionStorage.setItem('error', JSON.stringify(error))
					!!failCallBack && failCallBack('网络错误');
				}
			)
	},

}

const errorEnums = {
	200: '成功',
	0: '未知错误',
	1000: '数据库操作失败',
	1001: '数据库繁忙',
	1010: '参数错误',
	1011: '缺少参数',
	1100: '手机号有误',
	1101: '手机号已注册/绑定',
	1102: '发送手机验证码失败',
	1103: '发送手机验证码次数过多',
	1104: '手机验证码错误',
	1105: '手机验证码过期',
	1106: '手机验证码发送太频繁，1分钟后重试',
	1107: '不支持的国家/地区手机号',
	1108: '当前用户已经绑定手机号',
	1110: '邮箱名有误',
	1111: '邮箱名已注册/绑定',
	1130: '昵称有误',
	1131: '昵称已被使用',
	1132: '用户昵称太长',
	1133: '用户昵称太短',
	1140: '无效的身份证号码',
	1141: '该身份证号码已使用',
	1150: '无效的图形验证码',
	1151: '请先获取图形验证码',
	1200: '新旧密码一样',
	1201: '密码错误',
	1202: '非法密码',
	1203: '用户未激活',
	1204: '用户名或者密码错误',
	1210: '不支持的用户注册类型',
	1301: '获取微信用户ID失败',
	1302: '获取微信用户ID错误',
	1310: '该手机号已绑定其他用户',
	1320: '不支持的微信小程序',
	1400: '微信服务器返回错误',
	1401: '当前用户已绑定其他微信',
	1402: '当前用户已绑定微信',
	1500: '二维码失效，请刷新',
	2000: 'token失效',
	2010: 'token超时,请重新登录！',
	2011: 'TOKEN错误',
	2012: 'token未找到',
	2100: '请先绑定微信账号',
	2101: '请先绑定手机号',
	2102: '请先绑定邮箱',
	2103: 'saas服务器返回错误',
	2104: 'saas服务器返回解析失败',
	2105: 'saas服务器返回创建用户失败,请联系客服',
	2106: '请先设置登录密码',
	3000: '用户不存在',
	3001: '用户未登录',
	3002: '此手机号码未在翌飞产品/网站上注册',
	3003: '此微信身份ID未在翌飞产品/网站/小程序上注册',
	3004: '此用户未在友舱官网注册，请前往www.yctop.com',
	3005: '用户未在友舱小程序注册',
	3006: '用户未在指尖货运小程序注册',
	4000: '申请qiniu-token次数太多，请12小时后再试',
	4001: '生成qiniu-token失败',
	5000: '系统异常',
	5001: '转换xml异常',
}


const PASS_HANDLE = {

	post: function({url, data, header = {}}) {
		return new Promise((resolve, reject)=> {
			axios.post(url, qs.stringify(data), { headers: header})
				.then(function(response) {
					response = response.data
					if (!!response && !!response.code) {
						var code = response.code
						if (code == 200) {
							resolve(response.data)
						} else {
							reject(response.code, response.message);
						}
					} else {
						reject('请求失败，请联系客服')
					}
				},
				function(error) {
					reject('网络错误');
				}
			)
		})
	},

	$post: function({url, data, token, usrId}) {
		var header = {}
		header['Content-Type']= 'application/x-www-form-urlencoded';
		if (token) {
			header['Authorization'] = 'Bearer ' + token
		}
		if (usrId) {
			url = url + "?usrId=" + usrId
		}
		return this.post({url, data, header})
	},

	get: function({url, data, header = {}}) {
		return new Promise((resolve, reject)=> {
			axios.get(url, {'params': data, headers: header})
			.then(
				function(response) {
					response = response.data
					if (!!response && !!response.code) {
						var code = response.code
						if (code == 200) {
							resolve(response.data)
						} else {
							reject(errorHandler(response.code, response.message))
						}
					} else {
						reject('未知错误，请联系客服！')
					}
				},
				function(error) {
					reject('网络错误');
				}
			)
		})
	},

	$get({url, data, token, usrId}) {
		data.t = new Date().getTime()
		var header = {}
		if (token) {
			header['Authorization'] = 'Bearer ' + token
		}
		if (usrId) {
			data.usrId = usrId
		}
		return this.get({url, data, header})
	},

}

const AGENT_REQ_HANDLE = {

	$get:(url, query) => {
		if (!query) {
			query = {}
		}
		query.t = new Date().getTime()
		return new Promise((resolve, reject)=> {
			axios.get(url, {'params': query})
				.then(res=> {
					resolve(res)
				}).catch(
					err=> {
						reject(err)
				})
		})
	},

	$post:(url, param) => {
		return new Promise((resolve, reject)=> {
			axios.post(url, param).then(
				res => {
					let result=!!res.data ? res.data : res
					resolve(result)
				}
			).catch(
				err => {
					let res = err.response,
						code = !!res ? res.status : '',
						msg = !!res ? res.data.error : ''
					if (!!code) {
						reject({code, msg})
					} else {
						reject(err.message)
					}
				}
			)
		})
	},

	$put:(url, param) => {
		return new Promise((resolve, reject)=> {
			axios.put(url, param).then(
				res => {
					resolve(res.data)
				}
			).catch(
				err => {
					let res = err.response,
						code = !!res ? res.status : '',
						msg = !!res ? res.data.error : ''
					if (!!code) {
						reject({code, msg})
					} else {
						reject(err.message)
					}
				}
			)
		})
	},
	$delete: (url, param) => {
		return new Promise((resolve, reject)=> {
			axios.delete(url, param).then(
				res => {
					resolve(res)
				}
			).catch(
				err => {
					let res = err.response,
						code = !!res ? res.status : '',
						msg = !!res ? res.data.error : ''
					if (!!code) {
						reject({code, msg})
					} else {
						reject(err.message)
					}
				}
			)
		})
	},

}

export default {
	pass: PASS_HANDLE, 
	agent: AGENT_REQ_HANDLE,
};