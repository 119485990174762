/* eslint-disable */
import REQ_HANDLE from '@/utils/request.js';
import md5 from 'md5';
const YC_REQUEST_HANDLER = REQ_HANDLE.pass;
const AGENT_REQUEST_HANDLE = REQ_HANDLE.agent;
const actions = {

	wxAuth: ({state, commit, rootState}, payload) => {
		return new Promise((resolve, reject) => {
			let data = {
					code: payload.code,
					appid: 'wx60ced1c8de7908d5'
				},
				url = '/api-pass/wechat/h5/auth/code',
				success = res=> {
					console.log(res)
					let data = !!res.res ? res.res : res,
						{pass_token = '', wx_usr_info = {}, pass_usr_info = {}} = data
					commit('updateToken', {token:pass_token})
					commit('updateUsrInfo', {wx_usr_info, pass_usr_info})
					resolve()
				}, 
				fail = err=> {
					sessionStorage.setItem('request-error', JSON.stringify(err))
					reject(err)
				}
			YC_REQUEST_HANDLER.get({url, data}).then(success, fail)
		})
	},

	loginByPassToken: ({state, commit, rootState}, payload)=> {
		return new Promise((resolve, reject)=> {
			let url = '/api-mall/custom/login/pass/' + state.token,
				success = res=> {
					console.log(res)
					let {session} = res.data
					commit('updateMallUsrInfo', session)
					resolve()
				},
				fail = err=> {
					sessionStorage.setItem('request-error', JSON.stringify(err))
					reject()
				}
			AGENT_REQUEST_HANDLE.$get(url).then(success, fail)
		})
	},

	bindMobilePhone: ({state, commit, rootState}, payload)=> {
		return new Promise((resolve, reject) => {
			let data = {
					code: payload.code,
					token: state.token,
					phone: payload.phone,
					area: payload.area
				},
				url = '/api-pass/usr/add/mall/custom',
				success = res=> {
					console.log(res)
					let data = res,
						{usr, token} = data
					commit('updateToken', {token:token})
					commit('updateUsrInfo', {wx_usr_info: state.wx_usr, pass_usr_info: usr})
					resolve()
				}, 
				fail = err=> {
					reject(err)
					sessionStorage.setItem('request-error', JSON.stringify(err))
					
				}
			YC_REQUEST_HANDLER.post({url, data}).then(success, fail)
		})
	},
	getAgentName:({state, commit, rootState}, payload)=> {
		return new Promise((resolve, reject)=> {
			let url = '/api-mall/agent/' + payload.shopId,
				success = res=> {
					let name = res.data.name || '指尖货运小店',
						title=payload.title ? payload.title:state.metaInfo.title,
						newTitle= title + '_'+ name
					commit('updatePageTitle', {title:newTitle})
					resolve()
				},
				fail = err=> {
					reject()
				}
			AGENT_REQUEST_HANDLE.$get(url).then(success, fail)	
		})
	},
	getAgentInfo:({state, commit, rootState}, payload)=> {
		return new Promise((resolve, reject)=> {
			let url = '/api-mall/agent/' + payload.shopId,
				success = res=> {
					let data = !!res.data ? res.data : null
					resolve(data)
				},
				fail = err=> {
					reject()
				}
			AGENT_REQUEST_HANDLE.$get(url).then(success, fail)	
		})
	},
	
};

export default actions